import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';


const ProjectsClientsLogos = () => (
  <Container className="py-4 py-md-7">
    <Row>
      <Col className="text-center">
        <h1 className="font-weight-bolder">We've worked with</h1>
        <img src="/assets/what-we-do/projects-clients.png" className="img-fluid" alt=" " />
      </Col>
    </Row>
  </Container>
);

export default ProjectsClientsLogos;
